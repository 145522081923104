export const decisionData = {
  pilotState: {
    0: '空闲',
    1: '就绪',
    2: '行驶',
    3: '引导',
    4: '暂停',
    5: '到达',
    6: '对准',
    7: '错误'
  },
  type: {
    0: '设置目的地',
    1: '设置限速',
    2: '设置跟车距离',
    3: '设置车重',
    4: '启动',
    5: '缓慢停车',
    6: '紧急停车',
    7: '靠边停车',
    8: '对准',
    9: '强制向左变道',
    10: '强制向右变道',
    11: '向左变道',
    12: '向右变道'
  },
  state: {
    0: '已接收',
    1: '执行中',
    2: '成功',
    3: '失败'
  }
}

export const nodeData = {
  lidar: {
    left: '左雷达',
    right: '右雷达',
    top: '中雷达'
  },
  node: {
    localization: '融合定位',
    lidar_localization: '激光定位',
    obstacle_detection: '激光感知',
    reflector_measure: '对准',
    pnc: '规划控制',
    chassis: '底盘驱动',
    hdmap_server: '地图引擎',
    tos: 'TOS接口'
  }
}

export const ackData = {
  result: {
    1: '成功',
    2: '失败'
  }
}

export const tosData = {
  taskType: {
    'In-Load': '装船-装箱',
    'In-Unload': '装船-卸箱',
    'Out-Load': '卸船-装箱',
    'Out-Unload': '卸船-卸箱',
    'Move-Load': '转场-装箱',
    'Move-Unload': '转场-卸箱',
    TORemote: '远程指令',
    SGuild: '自动引导',
    'TOS-Cancelled': '任务取消',
    default: ''
  },
  containerType: {
    F20: '前20',
    '1R20': '后20',
    D20: '双20',
    M20: '多20',
    M40: '多40',
    40: 40,
    45: 45
  }
}

export const rtkStatusData = {
  0: '定位不可用',
  1: '单点定位 ',
  2: '伪距差分',
  4: 'RTK 固定解',
  5: 'RTK 浮点解 ',
  6: '惯导定位',
  7: '用户设定位置'
}

export const handleModeData = {
  artificial: '人工接管',
  automatic: '自动驾驶'
}

export const locationModeData = {
  0: '未融合准备',
  1: 'GNSS定位',
  2: 'LIDAR定位'
}

export const locationStatusData = {
  0: '正常定位',
  1: '轮速递推',
  2: '定位已丢失'
}
